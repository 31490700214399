import { User } from '@supabase/supabase-js';

export type UserSession = {
  id: string;
  email: string;
  fullName: string;
  avatar?: string;
  phone?: string;
};

export const parseSessionUser = (
  user?: User | null,
): UserSession | null | undefined => {
  if (!user) return null;

  return {
    id: user.id,
    email: user.user_metadata.email || user.email,
    fullName: user.user_metadata.fullName || '',
    avatar: user.user_metadata.picture || '',
  };
};
