'use client';

import { Toaster } from '@app/ui-system';
import { useToast } from '@app/ui-system/src/components/use-toast';
import Script from 'next/script';
import { ReactNode, useMemo } from 'react';
import { Provider } from 'urql';

import { AppConfigProvider } from '@/modules/app-config-provider/AppConfigProvider';
import { DarkModeProvider } from '@/modules/dark-mode-provider/DarkModeProvider';
import { ENV } from '@/services/config/config';
import { urqlClient } from '@/services/urql/urql-client';

const GTM_ID = ENV.NODE_ENV === 'production' ? 'G-0F0CKJ9LJB' : '';

const GoogleAnalytics = ({ GA_TRACKING_ID }: { GA_TRACKING_ID: string }) => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}');`}
      </Script>
    </>
  );
};

export function Providers({ children }: { children: ReactNode }) {
  const { toast } = useToast();

  const memoUrql = useMemo(() => urqlClient(toast), [toast]);

  return (
    <AppConfigProvider>
      <DarkModeProvider darkMode="dark">
        <GoogleAnalytics GA_TRACKING_ID={GTM_ID} />
        <Provider value={memoUrql}>{children}</Provider>
        <Toaster />
      </DarkModeProvider>
    </AppConfigProvider>
  );
}
